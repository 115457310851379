const baseUrls = {
  dev: 'https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/dev',
  sandbox: 'https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/sandbox',
  prod: 'https://kj6iukx25h.execute-api.eu-west-1.amazonaws.com/prod',
};

let env;

const hostname = window.location.hostname;

if (hostname === 'localhost' || hostname.includes('dev')) {
  env = 'dev';
} else if (hostname.includes('sandbox')) {
  env = 'sandbox';
} else {
  env = 'prod';
}

const baseUrl = baseUrls[env];

const apis = {
  add: {
    category: `${baseUrl}/abstracts`,
    subCategory: `${baseUrl}/class`,
    object: `${baseUrl}/objects`,
  },
  get: {
    categories: `${baseUrl}/abstracts`,
    subCategories: `${baseUrl}/class`,
    objects: `${baseUrl}/objects`,
  },
  update: {
    category: `${baseUrl}/abstracts`,
    subCategory: `${baseUrl}/class/`,
    object: `${baseUrl}/objects/`,
  },
  delete: {
    category: `${baseUrl}/abstracts`,
    subCategory: `${baseUrl}/class/`,
    object: `${baseUrl}/objects/`,
  },
  path: {
    get: `${baseUrl}/tree`,
    add: `${baseUrl}/tree`,
    delete: `${baseUrl}/tree`
  }
};

export default apis;
